import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import contactImg from "../images/contact-page.jpg"

import contact from "../images/contact.jpg"
import phone from "../images/phone.jpg"
import mail from "../images/mail.jpg"
import linkedin from "../images/linkedin.jpg"
import facebook from "../images/facebook.jpg"

import "../styles/contact.css"

const Contact = () => (
  <Layout>
    <SEO title="projects" />
    <div
      style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
    >
      {/*<img src={contactImg} /><h1>Contact</h1>*/}

      <span className="contactcontainer">
        <a>
          <img className="contactimg" src={contact} />
        </a>
        <a href="tel:(0033) 6.83.08.73.12">
          <img className="contactimg" src={phone} />
        </a>
        <a href="mailto:fonsiefilms@gmail.com">
          <img className="contactimg" src={mail} />
        </a>
        <a href="https://www.facebook.com/mgscbi1">
          <img className="contactimg" src={facebook} />
        </a>
        <a href="https://www.linkedin.com/in/danny-fonseca-433317102/">
          <img className="contactimg" src={linkedin} />
        </a>
      </span>
    </div>
  </Layout>
)

export default Contact
